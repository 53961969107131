import React, {useEffect, useRef, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Image from 'next/image';

import Checkers from '../../../../helpers/class/Checkers';
import ViewPort from '../../../ViewPort';
import {useSelector} from "react-redux";
import ReadMoreText from "../../../ReadMoreText";
import Link from "next/link";

export default function ZigZagType ( props ) {

  const showFullContent = props.hasOwnProperty('showFullContent') ? props.showFullContent : false

  const myRefs = useRef([]);
  myRefs.current = [];

  const [ nextElementHeight, updateNextElementHeight ] = useState([])

  const addToRefs = ( el ) => {
    if ( el && !myRefs.current.includes( el.children[0]) ) {
      myRefs.current.push( el.children[0]);
    }
  };

  const setHeight = ( target, images ) => {
    const element = target.parentElement.parentElement;
    let height = 500;
    // console.log(element.children[0].children[1]);
    setTimeout( () => {
      if ( element.style.height === "auto" && images ) {
        if ( element.nextSibling.children[0].clientHeight > 500 ) {
          height = element.nextSibling.children[0].clientHeight;
        }
        element.style.height = height + 'px';
        // element.children[0].children[1].classList.remove('invisible');
      }
    }, 500 );
  }

  const builder = props.builder;
  const content = props.content;
  const articles = props.articles;

  let container_class = 'container-fluid';
  if ( props.type == 'blog' && Checkers.isValidString( builder.builder_news_view_width ) ) {
    container_class = builder.builder_news_view_width;
  } else if ( props.type == 'category' && Checkers.isValidString( content.category_view_width ) ) {
    container_class = content.category_view_width;
  }

  const isMobile = useSelector(state => state['appSettings']['isMobile'])

  let skeletonArticles = [];
  for ( let i=0; i<6; i++ ) {
    const row_class = !( i % 2 == 0 ) ? 'row flex-sm-row-reverse w-100 m-0' : 'row w-100 m-0';
    const text_class = !( i % 2 == 0 ) ? 'col-12 col-sm-12 col-md-6 text-start text-md-end' : 'col-12 col-sm-12 col-md-6';
    skeletonArticles.push(
      <div key={i.toString()} className={row_class}>
        <div className="col-12 col-sm-6 p-0">
          <Skeleton height={400} className="col-12"/>
        </div>
        <div className={text_class + '\ py-5'}>
          <Skeleton height={18} className="col-12 col-sm-6 col-md-4"/>
          <Skeleton height={25} className="col-12 col-sm-8 col-md-6 mb-3"/>

          <Skeleton height={18} className="col-12 col-sm-8"/>
          <Skeleton height={18} className="col-12 col-sm-8"/>
          <Skeleton height={18} className="col-12 col-sm-8"/>
          <Skeleton height={18} className="col-12 col-sm-8"/>
          <Skeleton height={18} className="col-12 col-sm-8"/>
          <Skeleton height={18} className="col-12 col-sm-8"/>
          <Skeleton height={18} className="col-12 col-sm-8"/>
        </div>
      </div>
    );
  }

  return (
    <div className={container_class + '\ p-t-0 p-b-0  p-l-0 p-r-0'}>
      {
        props.loadingArticles ?
            skeletonArticles
            : articles.map( ( article, key ) => {
                  let flag = article.has_description == '1' || article.has_body

                  let row_class = 'row row-flex w-100 m-0';
                  let text_class = 'col-12 col-sm-12 col-md-6';
                  if (!(key % 2 == 0)) {
                    row_class = 'row flex-sm-row-reverse w-100 m-0';
                    text_class = 'col-12 col-sm-12 col-md-6 text-start text-md-end';
                  }
                  row_class += '\ zig-photo';
                  const subtitle = props.articlesContentType == 'article' ? article.subtitle : props.articlesContentType == 'blog' ? article.title : article.description;
                  const description = props.articlesContentType == 'article' || props.articlesContentType == 'blog' ? article.description : article.body;
                  const created = props.articlesContentType == 'blog' ? moment(new Date((article.created) * 1000)).format('dddd Do MMMM') : null;

                  if ((props.hasOwnProperty('articlesLimit') && (key < props.articlesLimit || props.articlesLimit === null)) || !props.hasOwnProperty('articlesLimit')) {
                    return (
                        <ViewPort
                            parentClassName={row_class}
                            key={article.id.toString()}
                            skeletonItem={
                              <>
                                <div className="col-12 col-sm-6 p-0">
                                  <Skeleton height={400} className="col-12"/>
                                </div>
                                <div className={text_class + '\ py-5'}>
                                  <Skeleton height={18} className="col-12 col-sm-6 col-md-4"/>
                                  <Skeleton height={25} className="col-12 col-sm-8 col-md-6 mb-3"/>

                                  <Skeleton height={18} className="col-12 col-sm-8"/>
                                  <Skeleton height={18} className="col-12 col-sm-8"/>
                                  <Skeleton height={18} className="col-12 col-sm-8"/>
                                  <Skeleton height={18} className="col-12 col-sm-8"/>
                                  <Skeleton height={18} className="col-12 col-sm-8"/>
                                  <Skeleton height={18} className="col-12 col-sm-8"/>
                                  <Skeleton height={18} className="col-12 col-sm-8"/>
                                </div>
                              </>
                            }
                        >
                          <div key={key.toString()} className={row_class}>
                            <div className="col-12 col-sm-12 col-md-6 p-0 zig-photo-container"
                                 style={{height: isMobile ? "400px" : ''}}
                                 onLoad={(e) => {
                                   setHeight(e.target, Checkers.isValidString(article.image));
                                   if (nextElementHeight.length === 0) {
                                     updateNextElementHeight(myRefs.current);
                                   }
                                 }}
                            >
                              {
                                  Checkers.isValidString(article.image) &&
                                  //showImageState == 'image-'+article.id &&
                                  <Image
                                      layout={'fixed'}
                                      sizes={'75vw'}
                                      quality={'75'}
                                      height={1600}
                                      width={1000}
                                      src={article.image}
                                      alt={'Post Img'}
                                      placeholder={'blur'}
                                      blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                                  />
                              }
                            </div>
                            <div ref={addToRefs} className={text_class + " align-items-center"}>
                              <div className={' pb-5 pt-5'}>
                                <div className="py-5 px-3">
                                  <h4 className={props.textColor + " block-title mb-0"}>{article.title}</h4>
                                  <h2 className={props.textColor + " mt-0 fw-normal hidden-xs hidden-sm hidden-md"}>{subtitle}</h2>
                                  <h2 className={props.textColor + " mt-0 fw-normal visible-xs visible-sm visible-md"}>{subtitle}</h2>
                                  {created != null && <p>{created}</p>}
                                  {
                                    showFullContent ?
                                        <>
                                          {
                                            description != null ?
                                                <>
                                                  <div className={props.textColor}
                                                       dangerouslySetInnerHTML={{__html: description}}/>
                                                </>
                                                :
                                                <></>
                                          }
                                        </>
                                        :
                                        <>
                                          {
                                            description != null ?
                                                <>
                                                  <div className={props.textColor}
                                                       dangerouslySetInnerHTML={{__html: description.slice(0, 250) + (description.length > 0 ? '...' : '')}}/>
                                                </>
                                                :
                                                <></>
                                          }
                                          {
                                            (article.has_description == '1' || article.has_body) && article.loggiaUrl != null && article.loggiaUrl.length > 0 && article.loggiaUrl.startsWith("/") ?
                                                <>
                                                  <Link href={props.updateHref( article )}>
                                                    <a onClick={() => props.clickHandler(article)} className={'btn-link text-link fs-16'}>Read more</a>
                                                  </Link>
                                                </>
                                                :
                                                <></>
                                          }
                                        </>
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                        </ViewPort>
                    )
                  }

                })
      }
    </div>
  )
}
